<template>
    <div class="columns">
      <div class="column">
        <!-- <transition name="slide" mode="out-in"> -->
        <router-view />
        <!-- </transition> -->
      </div>
    </div>
  </template>
<script>
export default {
  name: 'exchange-rates',
  components: {},
  data() {
    return {}
  }
}
</script>
